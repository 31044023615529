<template>
    <div class="thanks">拒绝访问!</div>
</template>

<script>
import { locale } from 'moment'
// import func from 'vue-editor-bridge'
    export default {
        name: "Thanks"
    }
    window.location.href='/login';
</script>

<style scoped>
.thanks{

    text-align: center;
    font-size: 30px;
    color: darkgray;
}
</style>
